.desktop {
    width: 900px;
    padding: 20px;
    border: 1px solid black;
    background: #ececec;
}

.desktop-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: white;
    padding: 10px;
}

.desktop-top-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.desktop-top-header span {
    color: forestgreen;
}

.header {
    font-size: 20px;
    color: #1a0dab;
}

.fade {
    animation-name: fadeIn;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}


.sitelink-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 40px;
}

.box h2 {
    color: #1a0dab;
}

.tableData tr th:last-child {
    position: sticky;
    right: 0;
    z-index: 2;
    background: #EBF8FF;
}

.tableData tr td:last-child {
    position: sticky;
    right: 0;
    z-index: 2;
    background: white;
}