
.campaign-option{
    color: #363849!important;
    border: 2px solid rgba(167, 169, 189, 0.2)!important;
    border-radius: 4px;
    font-weight: 500;
    font-family: Poppins;
}

.add-campaign-option{
    color: #1F95FF!important;
    border: 2px solid rgba(167, 169, 189, 0.2)!important;
    border-radius: 4px;
    font-weight: 500;
    font-family: Poppins;
}

select{
    border-radius: 5px!important;
    font-family: "Poppins";
    font-weight: 500!important;
    font-size: 15px!important;
}

label,option,input{
    font-size: 15px!important;
    font-weight: 500!important;
}