.notification-main{
    width: 450px;
    max-height: 500px;
    overflow: auto;
}

.review-btn{
    padding: 6px 14px;
    border-radius: 4px;
    background: #3B82F6;
    color: white;
}
