@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.main {
    font-family: 'Open Sans', sans-serif;
}

.description {
    word-spacing: 2px;
    margin: 10px 0;
    font-size: 16px;
}

.previewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.account {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.account .account-profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.account button {
    padding: 12px 16px;
    background-color: #efefef;
    border-radius: 1000px;
}

.account .account-profile img {
    border-radius: 50%;
}

.comment {
    margin: 30px 0;
    display: flex;
    gap: 10px;
    align-items: center;
}

.comment-box {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.comment-box .user-name {
    width: 50px;
    height: 50px;
    background-color: #efefef;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
}

.comment-input-box {
    flex-grow: 1;
    border-radius: 1000px;
    border: 1px solid #efefef;
    padding: 0 20px;
}

.comment-input-box input {
    width: 100%;
    height: 50px;
    background-color: transparent;
    border: none;
    outline: none;
}

.pinterest-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header-left-icon {
    display: flex;
    gap: 20px;
    font-size: 24px;
}

.header-right {
    display: flex;
    gap: 10px;
}

.option {
    display: flex;
    align-items: center;
    gap: 5px;
}

.save {
    padding: 12px 16px;
    background-color: red;
    border-radius: 1000px;
    color: white;
}

.pinterest-video {
    width: 100% !important;
    height: 100% !important;
}

.pinterest-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-prev , .swiper-button-next{
    color: white!important;
    font-weight: 800!important;
}

.swiper-button-prev:after{
    font-size: 20px!important;
}


.swiper-button-next:after{
    font-size: 20px!important;
}

.user_img{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: contain;
}