.upload-main{
    display: grid!important;
    grid-template-columns: 40% 60%!important;
}

@media screen and (max-width: 992px) {
    .upload-main{
        grid-template-columns: 100%!important;
    }
}

.upload-main div[role="presentation"] {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.upload-main div[role="presentation"] > div > img {
    margin: 0 auto!important;
}

.image-listing{
    padding: 20px;
}

.file-box{
    padding: 10px;
    margin: 10px 0;
    font-size: 12px;
    border-radius: 10px;
    border: 2px solid rgba(167, 169, 189, 0.2);
}