:root {
    --primaryBC: #000;
    --range: #ffffff59;
    --bar: linear-gradient(
            90deg,
            rgba(238, 29, 82, 1) 0%,
            rgba(255, 255, 255, 1) 34%,
            rgba(105, 201, 208, 1) 65%
    );
    --primary: #fff;
    --third: #9f9f9f;
    --poppins: "Poppins", sans-serif;
}

.containers {
    position: relative;
    border-top: 10px solid black;
    border-right: 2px solid black;
    border-left: 2px solid black;
    border-bottom: 7px solid black;
    border-radius: 18px;
}

.videos {
    height: 100%;
    width: 100%;
    object-fit: fill;
    background-color: black;
}

.tops {
    z-index: 2;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translatex(-50%);
}

.overlay-box {
    width: 100%;
    height: 100%;
    background-color: #00000050;
    position: absolute;
    top: 0;
}

.labels {
    font-size: 0.8em;
    color: var(--primary);
}

.label-mains::after {
    content: "|";
    margin: 0 0.5em;
}

.label-actives {
    font-weight: 600;
}

.bottoms {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.progress-durations {
    margin-left: 5px;
    font-size: 0.7em;
    color: var(--primary);
    opacity: 0.5;
    transition: all 0.3s ease;
}

.progress-ranges {
    height: 3px;
    margin: 5px;
    width: calc(100% - 10px);
    background-color: var(--range);
    cursor: pointer;
    transition: all 0.4s ease;
    border-radius: 100px;
}

.progress-ranges:hover {
    height: 5px;
}

.progress-bars {
    border-radius: 100px;
    height: 100%;
    width: 1%;
    transition: width 0.3s ease;
    background: var(--bar);
}

.progress-ranges:hover .progress-durations {
    opacity: 1;
}

.iconss {
    height: 54px;
    padding: 0 10px;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
}


.icons-bottom{
    padding-top: 4px;
    width: 25px;
    height: 25px;
}
.icons-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.65em;
}
.bottom-icons-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4em;
}
.icons-img img{

    height: 14px;
    width: 14px;
}
.icons-item-adds {
    height: 32px;
    width: 44px;
    overflow: hidden;
    background-color: var(--primary);
    border-radius: 9px;
    border-right: 2px solid #69c9d0;
    border-left: 2px solid #ee1d52;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rights {
    position: absolute;
    bottom: 220px;
    right: 15px;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
}

.right-icons {
    margin: 16px 0;
}

.icons img {
    height: 38px;
    width: 38px;
}

.icon-labels {
    color: var(--primary);
    font-size: 0.75em;
    padding-top: 1px;
    letter-spacing: .05em;
}

.comments-heads {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: -10px;
    padding: 10px 0;
    background-color: #fff;
}

.comments-head-labels {
    font-size: 0.7em;
    color: var(--third);
}

.comments-head-closes {
    cursor: pointer;
}

.comments-containers {
    position: absolute;
    bottom: 0;
    height: 75%;
    padding: 10px;
    border-radius: 20px 20px 0 0;
    z-index: 99;
    width: calc(100% - 20px);
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    transform: translatey(100%);
    overflow-y: scroll;
}

.comments-containers::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.comments-lists {
    display: flex;
    flex-direction: column;
}

.comments-items {
    padding: 10px 0;
}

.comments-actives {
    transform: translatey(0%);
}

#comments-icons {
    cursor: pointer;
}

#likes-icons {
    cursor: pointer;
}

.user-names {
    font-weight: 500;
    color: var(--primaryBC);
    font-size: 0.9em;
}

.user-times {
    margin: 0 0 0.75em 0;
    font-size: 0.75em;
}

.user-comments {
    font-size: 0.9em;
}

.overlays {
    position: absolute;
    z-index: 99;
    height: 100%;
    border: 0;
    width: 100%;
    background-color: #0f0f0f8a;
}

.howtos {
    position: relative;
    padding: 10px;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
}

.howto-closes {
    outline: none;
    width: 93%;
    padding: 8px 0;
    border-radius: 6px;
    font-weight: 600;
    color: var(--primary);
    background-color: var(--primaryBC);
    right: 0;
    cursor: pointer;
    margin: 0 auto;
    position: absolute;
    bottom: 10px;
    left: 0;
    border: 3px solid transparent;
    transition: all 0.6s ease;
    font-family: inherit;
}

.howto-closes:hover {
    border: 3px solid #333;
}

.explains {
    position: absolute;
    width: calc(100% - 40px);
    background-color: #000000b8;
    padding: 10px;
    border-radius: 6px;
    bottom: 60px;
}

.explain-videos {
    color: #69c9d0 !important;
}

.explain-video-bars {
    height: 4px;
    border-radius: 100px;
    width: 100%;
    background-color: var(--range);
    margin: 4px 0;
}

.explain-video-bar-ws {
    height: 100%;
    width: 65%;
    background: var(--bar);
    border-radius: 100px;
}

.explain-lists {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.explain-detailss {
    list-style: none;
}

.explain-details-heads {
    font-size: 0.8em;
    margin-bottom: 0.25em;
    color: #ee1d52;
}

.explain-details-descs {
    color: var(--primary);
    font-size: 0.8em;
}

.ads-details {
    position: absolute;
    bottom: 9%;
    padding: 10px 20px;
    width: 85%;
    color: white;
}

.ads-details button {
    width: 100%;
    background: grey;
    padding: 10px;
    color: white;
    border-radius: 10px;
    letter-spacing: 0.5px;
    font-size: 14px;
    opacity: 0.95;
}

.ads-details h2 {
    font-size: 18px;
    margin-bottom: 16px;
}

.sponsored p{
    font-size: 18px;
    margin-bottom: 16px;
}
.music-icon img{
    width: 18px;
}

.promoted-music {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-bottom: 12px;

}

.promoted-music h3{
    font-size: 18px;
}

.sponsored button{
    width: 21%;
    background: lightgrey;
    padding: 0 1px;
    color: black;
    border-radius: 3px;
    font-size: 10px;
    font-weight: bolder;
    letter-spacing: -0.2px;
    cursor: default;
}

.sign-up-button img{
    position: absolute;
    bottom: 11.5%;
    left: 62%;
    width: 8px;
    cursor: pointer;

}

.notification-badge button{
    border-radius: 100px;
    background: yellow;
    color: black;
    font-family: "Segoe UI";
    font-size: 12px;
    font-weight: bold;
    padding: 0 7px;
    position: absolute;
    bottom: 70%;
    left: 71%;
    cursor: default;
}