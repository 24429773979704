.input-box {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

.input-box:hover .custom-tooltip{
    display: block;
}

.input-box:focus .custom-tooltip{
    display: block;
}


.input-box .custom-tooltip{
    position: absolute;
    top: 100%;
    left: 0;
    padding: 16px 20px;
    background: #3B82F6;
    box-shadow: 6px 6px 19px rgba(167, 169, 189, 0.2);
    z-index: 10000;
    color: white;
    border-radius: 6px;
    display: none;
    width: 400px;
}

.custom-tooltip .top{
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 0;
    transform: rotateZ(45deg) translateY(-50%);
    z-index: -1;
    background: #3B82F6;
}

.mobile {
    width: 500px;
    padding: 20px;
    border: 1px solid black;
    background: #ececec;
}

.mobile-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: white;
    padding: 10px;
}

.mobile-top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    font-size: 20px;
    color: #1a0dab;
}

.sitelink {
    padding: 10px;
}

.fade {
    animation-name: fadeIn;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.sitelink ul {
    list-style: none;
    color: #1a0dab;
}

.call {
    display: flex;
    gap: 10px;
    padding: 10px;
    align-items: center;
    border-top: 1px solid white;
}