@media screen and (min-width: 768px) {
    .chakra-steps li > div:first-child {
        position: relative;
    }

    .chakra-steps li > div:first-child > div:first-child {
        background: none;
        width: 25px;
        height: 25px;
    }

    .chakra-steps li[aria-disabled="false"] > div:first-child  > div:first-child > div svg {
        display: none;
    }

    .chakra-steps li[aria-disabled="false"] > div:first-child  > div:first-child {
        border: 5px solid #1F95FF;
    }

    .chakra-steps li[aria-disabled="true"] > div:first-child  > div:first-child {
        border: 5px solid #ececec;
    }


    .chakra-steps li > div:last-child {
        height: 4px;
        background: rgba(167, 169, 189, 0.2);;
    }

    .chakra-steps li > div:last-child{
        background: #ececec;
        height: 3px;
        border-top-width: 0;
        margin: 0;
    }

    .chakra-steps li > div:last-child[data-highlighted] {
        background: #1F95FF;
    }

    .chakra-steps li > div > div:first-child[data-highlighted] + div span {
        color: #1F95FF;
    }

    .chakra-steps li > div:first-child[data-highlighted] > div:first-child {
        border: 4px solid #ececec;
    }

    .chakra-steps li > div:first-child > div:first-child span{
        display: none;
    }

    .chakra-steps li > div:first-child > div:last-child span{
        position: absolute;
        bottom: 130%;
        font-size: 16px;
        left: 0;
        transform: translateX(-40%);
    }

    .chakra-steps li > div:first-child > div:last-child[aria-current="step"] span {
        color: #1F95FF;
    }

}
.create-campaign-box{
    width: 100%;
}

.create-campaign-main{
    width: 100%;
}

.form-step-main{
    margin-top: 10px;
    padding: 10px!important;
    border: 2px solid rgba(167, 169, 189, 0.2);
}

@media screen and (min-width: 992px) {
    .create-campaign-main{
        width: 75%;
    }
}

@media screen and (max-width: 992px) {
    .flex-for-mobile{
        display: flex!important;
        flex-direction: column!important;
    }

    .equal-none{
        display: none!important;
    }

    .url-item,
    .creative-item {
        display: block;
    }

    .css-1u8p9gb{
        flex-direction: column!important;
    }

    .household-income-box{
        flex-direction: column;
        align-items: flex-start;
    }
}

.disable{
    display: none !important;
}

