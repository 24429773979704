@import url('https://rsms.me/inter/inter.css');

body {
    font-family: "Inter, sans-serif";
}

.css-1uom237:focus-visible, .css-1uom237[data-focus-visible]{
    box-shadow: 0 0 0 1px #3B82F6 !important;
    border-color: #3B82F6 !important;
}
.css-1beh0u8[aria-selected=true], .css-1beh0u8[data-selected]{
    border-color: #3B82F6 !important;
    color: #3B82F6 !important;
}
.css-ncg7sj:hover, .css-ncg7sj[data-hover]{
    background: #3B82F6 !important;
}
.css-17l4fu5[aria-selected=true], .css-17l4fu5[data-selected]{
    border-color: #3B82F6 !important;
    color: #3B82F6 !important;
}
.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: lightcyan;
    opacity: .2;
    z-index: 1;
}

div[role="tablist"] button[aria-selected="true"]{
    color: #757998;
    font-weight: 600;
}

input, textarea{
    color: #363849!important;
    border: 2px solid rgba(167, 169, 189, 0.2)!important;
    border-radius: 4px;
    font-weight: 500;
    font-family: Poppins;
}

.profile-input{
    color: #757998!important;
    pointer-events: none;
    border: none !important;
    padding: 0 !important;
    /*border: 2px solid rgba(167, 169, 189, 0.2)!important;*/
    border-radius: 4px;
    font-weight: 600;
    font-family: Poppins;
}

input[aria-invalid=true], textarea[aria-invalid=true] {
    border: 2px solid red!important;
    box-shadow: none;
}

.login-controller input[aria-invalid=true]{
    border: none!important;
    box-shadow: none!important;
}

@media screen and (min-width: 992px) {
    .css-2oqyjj{
        padding: 10px 100px!important;
    }
}

.profile-tab{
    font-weight: 600;
}

.profile-tab:hover{
    background: #3B82F6!important;
    color: white!important;
}

input:focus, textarea:focus{
    border: 2px solid #3B82F6!important;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.css-9k693q {
    margin-bottom: auto;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #bfc5cc;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #bfc5cc !important;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #bfc5cc !important;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bfc5cc !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bfc5cc !important;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #bfc5cc !important;
}
